import { cls, formatTimeRange } from '@/utils'
import Button from '@/components/button'
import IconArrowTopRight from '@haiper/icons-svg/icons/outline/arrow-top-right.svg'
import { HTMLAttributes, useCallback, useEffect, useMemo } from 'react'
import ShadowImageCard, { ShadowImageCardProps } from '@/components/shadow-image-card'
import useAmplitude from '@/hooks/useAmplitude'
import useEventAction from '@/hooks/useEventAction'

export interface EventCardProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<ShadowImageCardProps, 'variant' | 'className'> {
  data: haiper.MarketingEvent
  source: string
  hideButton?: boolean
}

/**
 * EventCard component
 * Event card is a card that displays an event, it usually contains an image, title, and description.
 * Also, it can contain a button to redirect to the event page.
 * Besides, it can contain a mask gradient background image on the left to make the text more readable.
 */
export default function EventCard({
  className,
  data,
  source,
  variant = 'default',
  hideButton,
  onClick,
  ...props
}: EventCardProps) {
  const timeRangeStr = useMemo(() => {
    return formatTimeRange(data.start_time, data.end_time)
  }, [data])

  const trackingEventParams = useMemo(() => {
    return {
      event_id: data.id,
      source,
    }
  }, [data, source])

  const action = data?.action ?? null

  const { track } = useAmplitude()

  useEffect(() => {
    track('view:event:card', trackingEventParams)
  }, [track, trackingEventParams])

  const { handleClick: handleEventClick } = useEventAction(data)

  const handleClick = useCallback(
    async (e: any) => {
      track('click:event:card', trackingEventParams)
      if (onClick) {
        onClick?.(e)
        return
      }

      handleEventClick?.(e)
    },
    [handleEventClick, onClick, track, trackingEventParams],
  )

  const showAbstract = !!data?.abstract

  return (
    <div
      {...props}
      className={cls('relative z-0 rounded-lg overflow-hidden border cursor-pointer h-30 w-70', className)}
      onClick={handleClick}
    >
      <ShadowImageCard variant={variant} src={data.image} alt='shadow image card' maskClassName={data?.mask_style} />
      <div
        className='z-10 text-text-on-color absolute inset-0 py-3 px-4 flex flex-col justify-between h-full'
        aria-label='foreground'
      >
        <div className='w-full flex flex-col gap-2'>
          <span className='text-heading-xl font-bold leading-[normal]'>{data.title}</span>
          {showAbstract && <span className='text-body-md leading-6 tracking-15'>{data.abstract}</span>}
          {timeRangeStr && <span className='text-body-md leading-6 tracking-15 font-extralight'>{timeRangeStr}</span>}
        </div>
        {hideButton || !action ? null : (
          <Button variant='outline' className='h-7 w-max rounded-full px-3 py-1 gap-0 text-text'>
            <div className='flex items-center'>
              <span className='px-1'>{action.name}</span>
              <IconArrowTopRight className='text-icon size-5' />
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}
