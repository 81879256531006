import useConfig, { useCachedConfig } from './useConfig'

const useMarketingEvents = () => {
  return useConfig<haiper.MarketingEvent[]>('marketing-events')
}

export const useCachedMarketingEvents = () => {
  return useCachedConfig<haiper.MarketingEvent[]>('marketing-events')
}

export default useMarketingEvents
