import { cls } from '@/utils'
import Image from '@/components/image'
import { HTMLAttributes } from 'react'

export interface ShadowImageCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  maskClassName?: string
  variant?: 'primary' | 'default'
  src: string
  alt: string
}

export const tailwindSafeList = ['from-[#5F392E]', 'from-[#AD6393]', 'from-[#88837B]']

export default function ShadowImageCard({
  className,
  maskClassName,
  variant = 'default',
  src,
  alt,
  ...props
}: ShadowImageCardProps) {
  return (
    <div {...props} className={cls('z-0 absolute inset-0', className)} aria-label='shadow image card'>
      <div
        className={cls(
          'z-10 absolute inset-0 to-transparent from-[36.54%] to-[68.85%] bg-gradient-to-ri',
          variant === 'primary' ? 'from-primary' : 'from-black',
          maskClassName,
        )}
        aria-label='mask'
      />
      <div className='z-0 absolute w-[70%] inset-y-0 right-0 bg-black/80' aria-label='image'>
        <Image src={src} alt={alt} className='w-full h-full object-cover object-center' />
      </div>
    </div>
  )
}
